import {call, take} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import * as budgetActionTypes from '../budgetsActionTypes';
import modalIds from '../../modalIds';
import updateBudgetsByTimePeriod from './updateBudgetsByTimePeriod';

const initiateBudgetsUpdateFlow = function* initiateBudgetsUpdateFlow() {
    while (true) {
        const {payload} = yield take(budgetActionTypes.INITIATE_BUDGETS_UPDATE_FLOW);
        const {budgetType} = payload;

        yield* openModal(modalIds.EDIT_BUDGETS_MODAL, {type: budgetType.toLowerCase()});

        const chosenModalOption = yield take([
            budgetActionTypes.CONFIRM_UPDATE_BUDGETS,
            budgetActionTypes.DECLINE_UPDATE_BUDGETS,
        ]);
        if (chosenModalOption
            && chosenModalOption.type === budgetActionTypes.CONFIRM_UPDATE_BUDGETS
        ) {
            const {budgetPeriodId, budgets} = chosenModalOption.payload;
            yield* closeModal(modalIds.EDIT_BUDGETS_MODAL, {
                type: payload.type,
            });
            yield call(updateBudgetsByTimePeriod, {
                payload: {budgetPeriodId, budgets},
            });
            continue;
        }

        yield* closeModal(modalIds.EDIT_BUDGETS_MODAL, {type: budgetType.toLowerCase()});
    }
};

export default initiateBudgetsUpdateFlow;
